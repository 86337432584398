import { render, staticRenderFns } from "./CreateProposalModal.vue?vue&type=template&id=11de4ffb&scoped=true&"
import script from "./CreateProposalModal.vue?vue&type=script&setup=true&lang=ts&"
export * from "./CreateProposalModal.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./CreateProposalModal.vue?vue&type=style&index=0&id=11de4ffb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11de4ffb",
  null
  
)

export default component.exports