import { concat, urlize } from "@/helpers/commons";
import store from ".";

const WIZIM_CACHE_KEY = "wizim-cache-key";

const isCacheSupported = (): boolean => "caches" in window;

export class Cache {
    private _cacheStorage: any;

    public async open(key: string = WIZIM_CACHE_KEY) {
        if (!isCacheSupported()) return;
        if (!this._cacheStorage) {
            this._cacheStorage = await caches.open(key);
        }
    }

    public async addCacheData(action: string, response: Response): Promise<void> {
        if (!isCacheSupported()) return;
        await this.open();
        return this._cacheStorage.put(action, response);
    }

    public async deleteCacheData(action: string): Promise<void> {
        if (!isCacheSupported()) return;
        await this.open();
        return this._cacheStorage.delete(action);
    }

    public async getCacheData(action: string): Promise<any> {
        if (!isCacheSupported()) return;
        await this.open();
        const cachedData = await this._cacheStorage.match(action);
        if (!cachedData) return null;
        return cachedData.json();
    }

    public async getStoreData(action: string, params?: any): Promise<any> {
        const cacheKey = concat([action, urlize(params)], "?");

        const cachedData = await this.getCacheData(cacheKey);
        if (cachedData) {
            return cachedData;
        }

        const response = await store.dispatch(action, params);
        await this.addCacheData(cacheKey, new Response(JSON.stringify(response)));
        return response;
    }
}
