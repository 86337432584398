
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Action, State } from "vuex-class";
import { IBusinessSectorDB, IHeader, IUserDB, StoreAction } from "@/types";
import Visualisations from "@/components/Visualisations.vue";
import PlanningTile from "@/components/ats/tileContent/PlanningTile.vue";
import moment from "moment-timezone";
import { capitalize } from "@/helpers/commons";
import { mixins } from "vue-class-component";
import PageMixin from "@/mixins/PageMixin.vue";
import TitleManager from "@/mixins/TitleManager.vue";
import PlanningCell from "@/components/ats/tileContent/PlanningCell.vue";
import ScheduleSelectionCell from "@/components/ats/tileContent/ScheduleSelectionCell.vue";
import { ROUTES } from "@/data";

@Component({
    name: "Planning",
    components: {
        PlanningTile,
        Visualisations,
        PlanningCell,
        ScheduleSelectionCell,

        // TODO: trouver une meilleure solution, sans cet import un probleme de composant recursif apparait
        MegaTableAts: () => import("@/components/ats/MegaTableAts.vue"),
    },
})
export default class Planning extends mixins(PageMixin, TitleManager) {
    @Prop() filters!: any;
    @Prop() readonly noHeader!: boolean;
    @Prop() readonly short!: boolean;

    pageTitle = this.$t("page.atsFollowing.planning.title");
    loading = false;
    timeout: any = 0;
    resizetimeout: any = 0;
    interims: any = [];
    data: any = [];
    agenda: any = [];
    boxes: any = [];
    selectedSchedule: number = 0;
    visualisations: any = {
        dateRange: {
            from: moment().startOf("month").valueOf(),
            to: moment().endOf("month").valueOf(),
        },
        mode: "month",
    };
    apiUrl: string = <string>process.env.VUE_APP_API_URL?.replace("/1.0", "") + "/";

    columnWidth = 0;

    moment = moment;

    $refs!: any;

    @Action("actions/getCandidatesAgenda") getCandidateAgenda!: StoreAction;

    @State("selectedCollaborators") selectedCollaborators!: IUserDB[];
    @State("selectedDomains") selectedDomains!: IBusinessSectorDB[];

    @Watch("selectedCollaborators", { deep: true })
    onCollabs() {
        this.timedInit();
    }

    @Watch("selectedDomains", { deep: true })
    onDomains() {
        this.timedInit();
    }

    @Watch("filters", { deep: true })
    onfilters() {
        this.timedInit();
    }

    @Watch("visualisations", { deep: true })
    onVisualisations() {
        this.timedInit();
    }

    @Watch("$route.query.mode")
    onMode(n: string, o: string) {
        if (o && !n) {
            this.visualisations.mode = "week";
            this.timedInit();
        }
    }

    get queryMode() {
        return this.$route.query.mode;
    }

    // Uncomment this if days are necessary

    // get isDaySubview() {
    //     return this.visualisations.mode === "day"
    // }

    get isAtsmodal() {
        return this.$route.name === ROUTES.APP.ATS.FOLLOWING._ROOT || this.$route.name === ROUTES.APP.ATS.CANDIDATE.FOLLOWING;
    }

    get isWeekSubview() {
        return this.visualisations.mode === "week";
    }

    get headers(): IHeader[] {
        const fromDate = moment(this.ranges.from);
        let headers: any = [];
        let i = 0,
            format;

        if (this.isWeekSubview) {
            format = "dddd DD";
            while (i < 7) {
                const date = fromDate.clone().add(i, "day");

                headers.push({
                    label: capitalize(date.format(format)),
                    value: date.valueOf(),
                });

                ++i;
            }
        }

        // Uncomment this if days are necessary

        // else if (this.isDaySubview) {
        //     const date = fromDate.clone();
        //     const format = "dddd DD MMMM"
        //     headers.push({
        //         label: capitalize(date.format(format)),
        //         value: date.valueOf()
        //     });
        // }
        else {
            headers = moment.weekdays(true).map((d: string) => ({ label: capitalize(d) }));
        }

        return headers;
    }

    get rows() {
        const rows: any = [];

        if (this.agenda) {
            if (this.isWeekSubview) {
                this.headers.forEach((header: IHeader, index: number) => {
                    const date = moment(header.value);
                    const row: any = this.eventBetweenDate(date);
                    rows.push(row);
                });
            } else {
                const fromDate = moment(this.ranges.from);
                let nb = fromDate.daysInMonth();
                let i = 0;
                let rowsIndex = 0;
                let firstDay;
                let firstDateDay = fromDate.weekday();

                if (firstDateDay !== 0) {
                    firstDay = fromDate.clone().subtract(firstDateDay, "day");
                    nb += firstDateDay;
                } else {
                    firstDay = fromDate;
                }

                while (i < nb) {
                    const cellDate: any = firstDay.clone().add(i, "day");
                    let foundDate: any = [];

                    if (!rows[rowsIndex]) {
                        rows[rowsIndex] = [];
                    }

                    if (cellDate.month() === fromDate.month() || this.isAtsmodal) {
                        foundDate = this.eventBetweenDate(cellDate);
                    }

                    rows[rowsIndex].push({
                        timestamp: cellDate.valueOf(),
                        agenda: foundDate,
                        out: (firstDateDay > 0 && !this.isAtsmodal) || (this.isAtsmodal && this.isBeforeToday(cellDate)),
                    });

                    --firstDateDay;

                    if (++i % 7 === 0) {
                        rowsIndex += 1;
                    }

                    if (i === nb && rows[rowsIndex]) {
                        const rest = 7 - rows[rowsIndex].length;

                        if (rest) {
                            let j = 0;
                            while (j < rest) {
                                const cellDate: any = firstDay.clone().add(i + j, "day");
                                rows[rowsIndex].push({
                                    timestamp: cellDate.valueOf(),
                                    agenda: [],
                                    out: this.isAtsmodal ? false : true,
                                });
                                ++j;
                            }
                        }
                    }
                }
            }
        }

        return rows;
    }

    get ranges() {
        let fromDate, toDate;

        if (typeof this.visualisations.dateRange.from === "number") {
            fromDate = this.visualisations.dateRange.from;
            toDate = this.visualisations.dateRange.to;
        } else {
            fromDate = this.visualisations.dateRange.from.valueOf();
            toDate = this.visualisations.dateRange.to.valueOf();
        }

        return {
            from: fromDate,
            to: toDate,
        };
    }

    get formattedFilters() {
        return {
            search: this.filters.search,
            jobId: this.filters.jobId || undefined,
            jobOfferId: this.filters.jobOfferId || "",
            client: this.filters.client || undefined,
            from: this.ranges.from,
            to: this.ranges.to,
        };
    }

    get formatAgenda() {
        return [
            ...this.agenda.candidates.map((c: any) => {
                return { data: c, type: "interview" };
            }),
            ...this.agenda.jobOffers.map((j: any) => {
                return { data: j, type: "mission" };
            }),
        ];
    }

    registerView(modelName: string) {
        this.$emit("registerView", {
            modelName,
            filters: this.formattedFilters,
            mode: this.visualisations.mode,
        });
    }

    eraseView(selectedView: any) {
        this.$emit("eraseView", {
            nameSlug: selectedView.nameSlug,
            selectedView,
            filters: this.formattedFilters,
            mode: this.visualisations.mode,
        });
    }

    isToday(timestamp: number) {
        const date = moment(timestamp);
        const today = moment();

        return date.date() === today.date() && date.month() === today.month() && date.year() === today.year();
    }

    isBeforeToday(timestamp: number) {
        const date = moment(timestamp);
        const today = moment();

        if (date.month() < today.month()) return true;
        else if (date.month() === today.month() && date.date() < today.date()) return true;

        return false;
    }

    eventBetweenDate(value: number) {
        const row: any = [];
        this.agenda.forEach((el: any) => {
            if (
                el.data.interview &&
                moment(el.data.interview.date).date() == moment(value).date() &&
                moment(el.data.interview.date).month() == moment(value).month()
            ) {
                row.push(el);
            } else if (
                el.data.mission &&
                moment(el.data.mission.startAt).date() == moment(value).date() &&
                moment(el.data.mission.startAt).month() == moment(value).month()
            ) {
                row.push(el);
            }
        });
        return row;
    }

    timedInit() {
        this.loading = true;
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
            this.init();
        }, 300);
    }

    async init() {
        this.loading = true;
        try {
            this.agenda = await this.getCandidateAgenda(this.formattedFilters);
            this.agenda = this.formatAgenda;
        } catch (e) {
            console.error(e);
        }
        this.loading = false;
    }

    selectSchedule(data: any) {
        this.selectedSchedule = data.date;
        this.$emit("schedule", data);
        this.timedInit();
    }

    isSelectedSchedule(date: number) {
        return this.selectedSchedule === date;
    }

    destroyed() {}

    beforeMount() {
        this.timedInit();
    }
}
